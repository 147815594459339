<template>
  <div class="v4-common-list-container">
    <div class="container__header scrollObj">
      <router-link
        tag="div"
        :to="{
          path: newsPath
        }"
        active-class="active"
      >
        <p>news</p>
        <div class="line"></div>
      </router-link>
      <router-link
        tag="div"
        :to="{
          path: '/list/publication'
        }"
        active-class="active"
      >
        <p>publication</p>
        <div class="line"></div>
      </router-link>
    </div>
    <router-view class="container__inner"></router-view>
  </div>
</template>

<script>
export default {
  name: 'c-np-container',
  computed: {
    newsPath() {
      const routeName = this.$route.name;
      if (routeName === 'news-list' || routeName === 'news-list-2') {
        return this.$route.path;
      }
      return '/list/news';
    },
  },
  watch: {
    $route: {
      handler: 'init',
      immediate: true,
    },
  },
  methods: {
    init() {
      console.log(this.$route.name);
    },
  },
};
</script>

<style></style>
